import { createApiRef } from '@backstage/core-plugin-api'

export type App = {
  id: number
  name: string
  repo: string
  codeVersion: string
  stagingVersion: string
  prodVersion: string
}

export interface ReleaseManagerApi {
  getHealth(): Promise<{ status: string }>
  getApps(): Promise<App[]>
}

export const releaseManagerApiRef = createApiRef<ReleaseManagerApi>({
  id: 'plugin.release-manager.service',
})
