import React from 'react';

const BenderIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="32px" height="32px">
      <path fill="#a2b4cc"
            d="M59.248,13.304C58.612,13.968,56.595,15.5,51.5,15.5c-5.897,0-7.673-2.051-7.953-2.433 C34.504,15.726,28,22.583,28,31.25c0,1.096,0,3.54,0,5.25l-0.039,0.513C28,37,28,37,28,37s4,2,16,2c14.5,0,18-2,18-2s6,3,6,8 c0,1.844,0,4,0,9c0,6.355-7,9-15,9c-2.873,0-18.451,0-27,0v3.5c0,0,1.147,1.388,6.5,2.094V81H32c-3,0-4,1.5-4,2.5s0,4.5,0,4.5 s0,5,19.422,5C71.956,93,75,84.5,75,84.5s0-42.342,0-53.25C75,22.838,68.527,14.539,59.248,13.304z"/>
      <path fill="#c6dff1"
            d="M53,63c-4,0-32.625,0-32.625,0C16.302,63,13,59.698,13,55.625v-9.396C13,42,17.824,40.392,22,39 c6-2,6-2,6-2s4,2,16,2c14.5,0,18-2,18-2s6,3,6,8c0,1.844,0,4,0,9C68,60.355,61,63,53,63z"/>
      <path fill="#a2b4cc"
            d="M54.953,8.414l0.025-0.04L54.5,2.5V2h-6l-0.022,0.5L48,8.374C48.813,9.038,49.526,9.5,51.5,9.5 C53.434,9.5,54.276,9.057,54.953,8.414z"/>
      <path fill="#444445"
            d="M51.5,43.5h-30c-2.761,0-5,2.239-5,5v3v3c0,2.761,2.239,5,5,5h32c2.761,0,5-2.239,5-5v-3v-1 C58.5,46.634,55.366,43.5,51.5,43.5z"/>
      <path fill="#fef6aa"
            d="M51.5,43.5h-3.558c-4.313,1.972-8.04,3.866-8.273,3.985C38.936,48.83,38.5,50.359,38.5,52 c0,3.053,1.446,5.762,3.684,7.5H53.5c2.761,0,5-2.239,5-5v-3v-1C58.5,46.634,55.366,43.5,51.5,43.5z"/>
      <path fill="#fef6aa"
            d="M69,75.5c0-8.5-4.5-8.5-6.5-8.5s-10,2-23,2c-2.897,0-5.189-0.159-7-0.396v16.345 C34.297,85.668,38.558,87,45.5,87C70,87,69,81.5,69,75.5z"/>
      <path fill="#fef6aa"
            d="M39.675,47.482c-0.011,0.006-0.036,0.018-0.036,0.018c-2.214-1.903-4.437-3.202-6.236-4h-5.628 C24.652,45.056,22.5,48.273,22.5,52c0,3.053,1.446,5.762,3.684,7.5h11.619c0.859-0.668,1.609-1.471,2.198-2.391 C39.053,55.634,38.5,53.884,38.5,52C38.5,50.357,38.94,48.827,39.675,47.482z"/>
      <path fill="#c6dff1"
            d="M51.5,15.5c6.5,0,8-2.5,8-2.5S59,9,55.159,8.212C54.44,8.964,53.632,9.5,51.5,9.5 c-1.974,0-2.687-0.462-3.5-1.126C45.5,8.5,43.5,12.104,43.5,13C43.5,13,45,15.5,51.5,15.5z"/>
      <g>
        <path fill="#1f212b"
              d="M34.5,54h-3c-0.276,0-0.5-0.224-0.5-0.5v-3c0-0.276,0.224-0.5,0.5-0.5h3c0.276,0,0.5,0.224,0.5,0.5 v3C35,53.776,34.776,54,34.5,54z"/>
        <path fill="#1f212b"
              d="M55.5,54h-3c-0.276,0-0.5-0.224-0.5-0.5v-3c0-0.276,0.224-0.5,0.5-0.5h3c0.276,0,0.5,0.224,0.5,0.5 v3C56,53.776,55.776,54,55.5,54z"/>
        <path fill="#1f212b"
              d="M53,64H20.375C15.757,64,12,60.243,12,55.625v-9.396c0-4.951,5.369-6.74,9.684-8.178l6-2 c0.254-0.083,0.526-0.063,0.764,0.054C28.48,36.122,32.497,38,44,38c13.83,0,17.482-1.857,17.518-1.876 c0.287-0.151,0.641-0.163,0.93-0.019C62.715,36.239,69,39.446,69,45v9C69,60.168,62.869,64,53,64z M27.959,38.068l-5.643,1.881 C18.418,41.248,14,42.72,14,46.229v9.396C14,59.14,16.859,62,20.375,62H53c3.284,0,14-0.577,14-8v-9 c0-3.623-3.806-6.154-5.056-6.888C60.472,38.674,55.753,40,44,40C33.838,40,29.264,38.575,27.959,38.068z"/>
        <path fill="#1f212b"
              d="M53.5,60h-32c-3.032,0-5.5-2.467-5.5-5.5v-6c0-3.033,2.468-5.5,5.5-5.5h30 c4.136,0,7.5,3.364,7.5,7.5v4C59,57.533,56.532,60,53.5,60z M21.5,44c-2.481,0-4.5,2.019-4.5,4.5v6c0,2.481,2.019,4.5,4.5,4.5h32 c2.481,0,4.5-2.019,4.5-4.5v-4c0-3.584-2.916-6.5-6.5-6.5H21.5z"/>
        <path fill="#1f212b"
              d="M45.5,88c-9.648,0-14.293-2.519-14.485-2.626c-0.483-0.268-0.657-0.877-0.389-1.36 c0.268-0.482,0.877-0.657,1.359-0.388C32.026,83.648,36.431,86,45.5,86c11.696,0,18.357-1.221,20.963-3.843 c1.567-1.578,1.555-3.614,1.54-5.971L68,75.5c0-7.5-3.578-7.5-5.5-7.5c-0.487,0-1.572,0.166-2.945,0.375 C55.836,68.943,48.913,70,39.5,70c-11.403,0-13.896-2.395-14.281-2.875L25,66.851V63c0-0.552,0.447-1,1-1s1,0.448,1,1v3.035 C27.767,66.529,30.774,68,39.5,68c9.262,0,16.086-1.042,19.752-1.602C60.823,66.158,61.86,66,62.5,66c2.051,0,7.5,0,7.5,9.5 l0.003,0.673c0.017,2.569,0.033,5.225-2.122,7.393C64.834,86.633,57.932,88,45.5,88z"/>
        <rect width="1" height="16" x="32" y="69" fill="#1f212b"/>
        <path fill="#1f212b"
              d="M38.5,87c-0.276,0-0.5-0.224-0.5-0.5v-9c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v9 C39,86.776,38.776,87,38.5,87z"/>
        <rect width="1" height="18" x="50" y="69" fill="#1f212b"/>
        <path fill="#1f212b"
              d="M62.5,78c-0.276,0-0.5-0.224-0.5-0.5v-10c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v10 C63,77.776,62.776,78,62.5,78z"/>
        <path fill="#1f212b"
              d="M34.5,54h-3c-0.276,0-0.5-0.224-0.5-0.5v-3c0-0.276,0.224-0.5,0.5-0.5h3c0.276,0,0.5,0.224,0.5,0.5 v3C35,53.776,34.776,54,34.5,54z"/>
        <path fill="#1f212b"
              d="M55.5,54h-3c-0.276,0-0.5-0.224-0.5-0.5v-3c0-0.276,0.224-0.5,0.5-0.5h3c0.276,0,0.5,0.224,0.5,0.5 v3C56,53.776,55.776,54,55.5,54z"/>
        <path fill="#1f212b"
              d="M37.975,60H26.013l-0.136-0.105C23.413,57.981,22,55.104,22,52c0-3.814,2.128-7.242,5.554-8.948 L27.659,43l5.948,0.043c1.47,0.652,3.7,1.845,6.1,3.858l1.16-0.604l-0.753,1.424C39.375,49.073,39,50.512,39,52 c0,1.718,0.491,3.391,1.422,4.839l0.173,0.27l-0.173,0.27c-0.607,0.949-1.386,1.796-2.313,2.516L37.975,60z M26.357,59h11.271 c0.693-0.559,1.29-1.197,1.778-1.901C38.485,55.556,38,53.8,38,52c0-1.512,0.347-2.977,1.03-4.361 c-2.245-1.878-4.329-3.007-5.732-3.639h-5.402C24.873,45.552,23,48.607,23,52C23,54.736,24.222,57.278,26.357,59z"/>
        <path fill="#1f212b"
              d="M53.5,60H42.013l-0.136-0.105C39.413,57.981,38,55.104,38,52c0-1.658,0.414-3.258,1.23-4.754 l0.074-0.136l0.138-0.07c0.405-0.206,4.083-2.069,8.292-3.994L47.942,43H51.5c4.136,0,7.5,3.364,7.5,7.5v4 C59,57.533,56.532,60,53.5,60z M42.357,59H53.5c2.481,0,4.5-2.019,4.5-4.5v-4c0-3.584-2.916-6.5-6.5-6.5h-3.448 c-3.844,1.76-7.221,3.457-8.017,3.859C39.349,49.168,39,50.561,39,52C39,54.736,40.222,57.278,42.357,59z"/>
        <path fill="#1f212b"
              d="M51.5,16c-6.706,0-8.361-2.631-8.429-2.743L43,13.139V13c0-1.194,2.238-4.987,4.975-5.125 l0.192-0.01l0.149,0.122C49.087,8.617,49.686,9,51.5,9c2.046,0,2.704-0.514,3.298-1.134l0.191-0.2l0.271,0.056 c4.144,0.85,4.73,5.172,4.736,5.216l0.021,0.171l-0.089,0.148C59.861,13.369,58.206,16,51.5,16z M44.02,12.857 C44.42,13.317,46.247,15,51.5,15c5.204,0,7.045-1.651,7.471-2.133c-0.147-0.751-0.858-3.387-3.654-4.106 C54.508,9.531,53.525,10,51.5,10c-2,0-2.851-0.472-3.657-1.11C45.812,9.151,44.214,12.01,44.02,12.857z"/>
        <path fill="#1f212b"
              d="M43.5,13c0-0.099,0.028-0.236,0.076-0.394c0.001-0.004,0.002-0.007,0.003-0.011 c0.049-0.161,0.123-0.348,0.216-0.551l-0.309,0.022l-0.211,0.038C33.388,15.007,27,22.523,27,31.25v5.25c0,0.552,0.448,1,1,1 s1-0.448,1-1v-5.25c0-7.788,5.78-14.528,14.735-17.197l0.88-0.062C43.781,13.463,43.5,13,43.5,13z"/>
        <path fill="#1f212b"
              d="M59.345,12.308C59.464,12.724,59.5,13,59.5,13s-0.356,0.58-1.442,1.181l1.001,0.103 C67.437,15.371,74,22.823,74,31.25v53.031C73.47,85.265,69.02,92,47.422,92C30.237,92,29.013,88,29,88v-4.5 c0-0.417,0.539-1.5,3-1.5c0.552,0,1-0.448,1-1s-0.448-1-1-1c-3.955,0-5,2.29-5,3.5V88c0,1.002,0.984,6,20.422,6 c24.95,0,28.385-8.789,28.519-9.163L76,84.674V31.25C76,21.856,68.7,13.549,59.345,12.308z"/>
        <path fill="#1f212b"
              d="M48.001,8.874c-0.014,0-0.027,0-0.041-0.001c-0.275-0.022-0.48-0.264-0.458-0.539l0.478-5.874 c0.021-0.275,0.267-0.479,0.538-0.458c0.275,0.022,0.48,0.264,0.458,0.539l-0.478,5.874C48.478,8.676,48.259,8.874,48.001,8.874z"/>
        <path fill="#1f212b"
              d="M54.977,8.874c-0.258,0-0.477-0.198-0.497-0.459l-0.478-5.874c-0.022-0.275,0.183-0.517,0.458-0.539 c0.265-0.02,0.517,0.183,0.538,0.458l0.478,5.874c0.022,0.275-0.183,0.517-0.458,0.539C55.004,8.874,54.99,8.874,54.977,8.874z"/>
        <path fill="#1f212b"
              d="M38.5,73c-0.276,0-0.5-0.224-0.5-0.5v-3c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v3 C39,72.776,38.776,73,38.5,73z"/>
        <path fill="#1f212b"
              d="M43,76c-8.082,0-10.705-2.023-10.813-2.109c-0.216-0.173-0.25-0.487-0.078-0.703 c0.171-0.214,0.484-0.25,0.7-0.081l0,0C32.834,73.126,35.343,75,43,75c4.421,0,10.858-0.195,16.418-1.123 c0.269-0.049,0.529,0.138,0.575,0.411s-0.139,0.53-0.411,0.576C53.955,75.803,47.459,76,43,76z"/>
        <path fill="#1f212b"
              d="M43.542,82c-0.015,0-0.028,0-0.042,0c-6.765-0.001-11.033-1.964-11.212-2.047 c-0.25-0.117-0.357-0.414-0.241-0.664c0.116-0.25,0.414-0.358,0.665-0.242c0.042,0.02,4.262,1.952,10.788,1.953 c0.014,0,0.028,0,0.042,0c3.28,0,8.167-0.108,12.904-0.623c0.277-0.024,0.521,0.168,0.551,0.443 c0.03,0.274-0.169,0.521-0.443,0.551C51.772,81.891,46.847,82,43.542,82z"/>
        <path fill="#1f212b"
              d="M62.5,80.438c-0.23,0-0.438-0.16-0.488-0.394c-0.059-0.27,0.112-0.536,0.382-0.595 c2.843-0.62,4.969-1.413,6.319-2.359c0.227-0.157,0.539-0.104,0.696,0.123c0.159,0.226,0.104,0.538-0.122,0.696 c-1.46,1.022-3.708,1.869-6.681,2.517C62.57,80.435,62.535,80.438,62.5,80.438z"/>
        <path fill="#1f212b"
              d="M59.499,80.984c-0.242,0-0.455-0.177-0.493-0.424c-0.042-0.273,0.146-0.528,0.419-0.57l0.179-0.027 c0.272-0.042,0.545-0.083,0.812-0.129c0.268-0.048,0.531,0.135,0.578,0.408c0.047,0.272-0.136,0.531-0.408,0.578 c-0.273,0.047-0.553,0.09-0.831,0.132l-0.179,0.027C59.55,80.982,59.524,80.984,59.499,80.984z"/>
        <path fill="#1f212b"
              d="M62.5,85.821c-0.276,0-0.5-0.224-0.5-0.5V82.5c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v2.821 C63,85.597,62.776,85.821,62.5,85.821z"/>
        <path fill="#1f212b"
              d="M64.5,73.722c-0.214,0-0.412-0.139-0.479-0.354c-0.08-0.264,0.068-0.543,0.332-0.624 c1.61-0.492,2.915-1.053,3.878-1.666c0.231-0.146,0.542-0.081,0.69,0.153c0.148,0.233,0.079,0.542-0.153,0.69 c-1.054,0.671-2.402,1.252-4.122,1.778C64.598,73.715,64.548,73.722,64.5,73.722z"/>
      </g>
    </svg>
  );
};

export default BenderIcon;
