import {DiscoveryApi, IdentityApi} from "@backstage/core-plugin-api";
import {App, ReleaseManagerApi} from "./types";

export class ReleaseManagerApiClient implements ReleaseManagerApi {
  private readonly discoveryApi: DiscoveryApi
  private readonly identityApi: IdentityApi
  constructor(options: {
    discoveryApi: DiscoveryApi,
    identityApi: IdentityApi
  }) {
    this.discoveryApi = options.discoveryApi
    this.identityApi = options.identityApi
  }
  async getHealth(): Promise<{ status: string }> {
    const baseUrl =   await this.discoveryApi.getBaseUrl('release_manager')
    const response = await fetch(`${baseUrl}/health`)
    if (!response.ok) {
      throw new Error(response.statusText)
    }
    return await response.json()
  }

  async getApps(): Promise<App[]> {
    const { token: idToken } = await this.identityApi.getCredentials();
    const baseUrl =   await this.discoveryApi.getBaseUrl('release_manager')
    const response = await fetch(`${baseUrl}/releases`, {
      method: 'GET',
      headers: {
        ...(idToken && {Authorization: `Bearer ${idToken}`}),
      }
    })
    if (!response.ok) {
      throw new Error(response.statusText)
    }
    return await response.json()
  }
}

export default ReleaseManagerApiClient
